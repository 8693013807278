import React from 'react';
import abyss from 'abyss-react';
import PropTypes from 'prop-types';

class Header extends React.Component {
  render() {
    const {
      classes
    } = this.props;

    return (
      <div className="headerLayout">
        <div className="headerContainer">
          <p className={classes['logoText']}>Matt Gordon's Portfolio</p>
        </div>
      </div>
    );
  }
}

Header.styles = {
  logoText: {
    fontSize: '25px',
    margin: 0
  }
};

Header.propTypes = {
  classes: PropTypes.object.isRequired
};

export default abyss(Header);
