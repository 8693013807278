import React from 'react';
import {ThemeProvider} from 'react-jss';
import {ReduxProvider} from 'abyss-redux';
import {Router} from 'abyss-router';
import '../styles';
import theme from '../styles/theme';
import App from './App';

export default async (store, location) => {
  return (
    <ThemeProvider theme={theme}>
      <ReduxProvider store={store}>
        <Router {...location}>
          <App />
        </Router>
      </ReduxProvider>
    </ThemeProvider>
  );
};
