import {jss} from 'react-jss';
import app from './_app';
import layout from './_layout';

jss.createStyleSheet({
  '@global': {
    ...app,
    ...layout
  }
}).attach();
