import React from 'react';
import abyss from 'abyss-react';
import Switch from 'abyss-router/lib/Switch';
import Route from 'abyss-router/lib/Route';
import Redirect from 'abyss-router/lib/Redirect';
import PropTypes from 'prop-types';
import Construction from 'components/Construction';
import Home from './pages/Home';

class Content extends React.Component {
  render() {
    const {
      construction
    } = this.props;
    return (
      <div className="contentLayout">
        <div className="contentContainer">
          <Choose>
            <When condition={!construction}>
              <Construction />
            </When>
            <Otherwise>
              <Switch>
                <Route path="/" exact component={Home} />
                <Route render={() => <Redirect to="/" />} />
              </Switch>
            </Otherwise>
          </Choose>
        </div>
      </div>
    );
  }
}

Content.propTypes = {
  construction: PropTypes.bool
};

export default abyss(Content);
