import React from 'react';
import abyss from 'abyss-react';
import Helmet from 'react-helmet';
import Header from './Header';
import Content from './Content';
// import Footer from './Footer';

class App extends React.Component {
  render() {
    return (
      <div>
        <Helmet>
          <title>Matt Gordon's Portfolio</title>
        </Helmet>
        <Header />
        <Content />
      </div>
    );
  }
}

export default abyss(App);
