export default {
  body: {
    margin: 0,
    fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif',
    fontWeight: 200,
    color: '#333',
    fontSmoothing: 'antialiased',
    backgroundColor: '#fff'
  }
};
