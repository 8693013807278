export default {
  breakpoints: {
    xsmall: '@media (min-width: 480px)',
    small: '@media (min-width: 576px)',
    medium: '@media (min-width: 768px)',
    large: '@media (min-width: 992px)',
    xlarge: '@media (min-width: 1200px)',
    xxlarge: '@media (min-width: 1551px)'
  },
  sizing: {
    base: '16px',
    halfBase: '8px',
    quarterBase: '4px',
    baseLarge: '24px',
    baseDouble: '32px',
    baseQuadruple: '64px'
  }
};
