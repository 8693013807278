export default {
  '.headerLayout': {
    height: '50px',
    width: '100%',
    borderBottom: '1px solid #333',
    backgroundColor: '#000'
  },
  '.headerContainer': {
    width: '90%',
    margin: '0 auto',
    padding: '5px 15px',
    color: '#fff'
  },
  '.contentLayout': {
    width: '90%',
    margin: '0 auto'
  },
  '.contentContainer': {
    width: '100%',
    margin: '0 auto',
    padding: '0 15px'
  },
  '.textCenter': {
    textAlign: 'center'
  },
  '.textRight': {
    textAlign: 'right'
  },
  '.textLeft': {
    textAlign: 'left'
  },
  '.noMargin': {
    margin: '0 !important'
  },
  '.noPadding': {
    padding: '0 !important'
  }
};
