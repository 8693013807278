import React from 'react';
import abyss from 'abyss-react';
import Helmet from 'react-helmet';
// import PropTypes from 'prop-types';

class Home extends React.Component {
  render() {
    return (
      <div>
        <Helmet titleTemplate="%s | Matt Gordon's Portfolio">
          <title>Home</title>
        </Helmet>
        <div className="main">
          <p>
            Welcome to Matt Gordon's Portfolio. Here you will find pieces of his development
            work from over the years.
          </p>
        </div>
      </div>
    );
  }
}

export default abyss(Home);
